/* eslint-disable jsx-a11y/alt-text */
import "./choose.css";
import image from "../../assets/Frame 4-2.png"
const Choose = () => {
  return (
    <>
      <img src={image} className="centerChoose"/>
    </>
  );
};

export default Choose;
