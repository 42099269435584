import "./about.css"
const JobPost = () =>{
    return(
        <>
            <div className="jobPost">
                <h1>
                    Career
                </h1>

                <p>
                    No job vacancy at the moment
                </p>

                <h2>
                    Latest Job Posts
                </h2>

                <p>
                    Available jobs and roles
                </p>

                <div className="jobInline">
                    <div className="jobSquare"></div>
                    <div className="jobSquare"></div>
                    <div className="jobSquare"></div>
                </div>
            </div>
        </>
    );
};

export default JobPost;