import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
background-color: #FFF;
padding: 0px 10px;
position: relative;
overflow: hidden;
`;

export const NavLink = styled(Link)`
float: left;
color: black;
text-align: center;
padding: 0px;
margin: 20px;
text-decoration: none;
line-height: 25px;
border-radius: 0px;
color: #0C91BB;
font-family: 'DM Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
&.active {
    color: #010C6C;
    border-bottom: 1px solid #010C6C;
    padding-bottom: 5px;
}
&:not(.active):hover {
    cursor: pointer;
}
&:hover{
    border-bottom: 1px solid #010C6C;
    padding-bottom: 5px;
}
@media screen and (max-width: 768px) {
    font-size: 16px; /* Adjust font size for smaller screens */
}
`;

export const NavMenu = styled.div`
display: flex;
align-items: center;
margin-right: 4px;

@media screen and (max-width: 768px) {
    flex-direction: column; /* Change to a column layout for smaller screens */
    position: absolute;
    top: 70px; /* Adjust the top position as needed */
    left: -100%; /* Hide the menu by default */
    background-color: #FFF;
    color: #000;
    width: 100%;
    transition: all 0.3s ease;
    
    &.active {
        left: 0; /* Show the menu when active class is added */
    }
}
`;

export const MobileMenuButton = styled.div`
display: none; /* Hidden by default on larger screens */
cursor: pointer;

@media screen and (max-width: 768px) {
    display: block; /* Show the button on smaller screens */
    margin-right: 0px;
    font-size: 60px;
    color: #010C6C;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    float: right;
}
`;

export const Overlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: none; /* Hidden by default */
z-index: 1;
color: #000;
background-color: #FFF;

&.active {
    display: block;
    color: #FFF; /* Show the overlay when active class is added */
}
&.active {
    display: block;
    color: #fff;
    cursor: pointer;
}
`;

