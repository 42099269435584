import React, { useEffect } from 'react';
import Slider from '../components/SlideComponent/Slider';
import Specialty from '../components/ProductsPage/specialty';
import Columns from '../components/ProductsPage/columns';
import SendMessage from '../components/DetailsPage/sendMessage';
import Subscribe from '../components/HomePage/Subscribe/subscribe';
import "../App.css"

const ProductsTwo = () => {
	useEffect(() => {
		if (window.location.hash === '#scroll600') {
		  window.scrollTo(0, 600);
		}
		if (window.location.hash === '#scroll1200') {
			window.scrollTo(0, 2500);
		  }
		  if (window.location.hash === '#scroll1500') {
			window.scrollTo(0, 2900);
		  }
		  if (window.location.hash === '#scroll2000') {
			window.scrollTo(0, 3200);
		  }
	}, []);
	return (
		<div>
			<Slider />

			<h1 className='productsHeader'>
				What We Procure
			</h1>

			<div className='productsTwoPage'>
				<Specialty/>
				<Columns />
			</div>
		</div>
	);
};

export default ProductsTwo;
