import "./products.css";

import image1 from "../../assets/valve1.png"
import image2 from "../../assets/valve2.png"
import image3 from "../../assets/valve3.png"
import image4 from "../../assets/valve4.png"
import image5 from "../../assets/valve5.png"
import image6 from "../../assets/valve6.png"

const Valves = () => {
    return(
        <>
            <div className="productsContainer">
                <div className="topSpacing">
                    <h1>
                        Valves and Actuators Packages
                    </h1>

                    <div class="productValvesBG">
                        <div className="productContainerCenter">
                            <div class="row">
                                <div class="productColumn">   
                                    <div class="productTextContainer">
                                        <img src={image1} alt="" class="regularImage" />
                                        <div class="productCenteredText">
                                            <p>
                                            Gate Valves
                                            <br/>
                                            Globe Valves 
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productColumn">
                                    <div class="productTextContainer">
                                        <img src={image2} alt="" class="centeredProductImage" />
                                        <div class="productCenteredImageCont">
                                            <p>
                                            Ball Valves
                                            <br />
                                            Plug Valves
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productColumn">
                                    <div class="productTextContainer">
                                        <img src={image3} alt="" class="regularImage" />
                                        <div class="productCenteredText">
                                            <p>
                                            Plug Valves
                                            <br/>
                                            Butterfly Valves
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                    <div class="productColumn">   
                                        <div class="productTextContainer">
                                            <img src={image4} alt="" class="regularImage" />
                                            <div class="productCenteredText">
                                                <p>
                                                Interlocks
                                                <br/>
                                                Diaphragm Actuators
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="productColumn">
                                        <div class="productTextContainer">
                                            <img src={image5} alt="" class="centeredProductImageBottom" />
                                            <div class="centeredImageContBottom">
                                                <p>
                                                Electric Actuators 
                                                <br/>
                                                Hydraulic Actuators
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="productColumn">
                                        <div class="productTextContainer">
                                            <img src={image6} alt="" class="regularImage" />
                                            <div class="productCenteredText">
                                                <p>
                                                Pneumatic Actuators
                                                <br/>
                                                Gas Over Oil Actuators
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="table">
                        <div className="tableColCont">
                            <div className="row">
                                <div className="tableCol">
                                    <ul>
                                        <li>
                                        Gate Valves
                                        </li>
                                        
                                        <li>
                                        Globe Valves
                                        </li>
                                        
                                        <li>
                                        Check Valves
                                        </li>

                                        <li>
                                        Ball Valves
                                        </li>

                                        <li>
                                        Plug Valves
                                        </li>

                                        <li>
                                        Butterfly Valves
                                        </li>

                                        <li>
                                        Double Block and Beed (DBB) Valves
                                        </li>
                                        
                                    </ul>
                                </div>

                                <div className="tableCol">
                                    <ul>
                                        <li>
                                        Miscellaneous Valves
                                        <br/><br/>
                                            *Needle valves
                                            <br/><br/>
                                            *Sampling valves
                                            <br/><br/>
                                            *Quick acting line blinds
                                            <br/><br/>
                                            *Piston valves
                                            <br/><br/>
                                            *Diaphragm valves
                                        </li>
                                        
                                        <li>
                                        Electric Actuators 
                                        </li>
                                        
                                        
                                    </ul>
                                </div>

                                <div className="tableCol">
                                    <ul>
                                        <li>
                                        Hydraulic Actuators
                                        </li>

                                        <li>
                                        Pneumatic Actuators
                                        </li>

                                        <li>
                                        Gas Over Oil Actuators
                                        </li>

                                        <li>
                                        Diaphragm Actuators
                                        </li>

                                        <li>
                                        Interlocks
                                        </li>

                                        <li>
                                        Fire Proof Enclosure for valve and actuator protection
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Valves;