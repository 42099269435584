import React from 'react';
import '../App.css'
import Slider from '../components/HomePage/HeadSlide/headSlider';
import Services from '../components/HomePage/Services/services';
import Industries from '../components/HomePage/Industries/industries';
import Subscribe from '../components/HomePage/Subscribe/subscribe';

const Home = () => {
    const images = [
        'https://s3-alpha-sig.figma.com/img/cdca/41a7/ac0daac999dade92cc784924db2fb689?Expires=1694995200&Signature=XAlFkxbJdSs8R8VZh6-FUlumiISJ0DxWPdISfeyF~RK9s1MnFHW0hWbDGPXEjyVUiTZHYZhEvyi5D~teti1Oscdj1L9RisNZcBOs~b1meu-pdvqUyDM8vQwOciJ9T3i9au0RgiI1693WOR8~3s07mWi-YvoOBarXJ~Syfhuu4nr3f6Q-l0lgSNEIWf6RISR-rYRBVB7nIfAr7IKuPnu~ZO4aUqr90dlVfPCpL5nmIAi37zfHhF9zT0C7wogpm1vd0kpDqB8bB78hwqwJFzmbNyUkExSRvUvifE36xyHOY5ZbX6rDqTYjrlyB41NPz6y1tl~glmVpSN1dA2tjhql22A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        'https://s3-alpha-sig.figma.com/img/92bf/27a4/ba10aa98b175d18cf861d59200116fcb?Expires=1694995200&Signature=h8XU5zjWa0MbWafHPpJbh82MsYF3RXL~CiC06-0ve7XtW1XIiDSnM6KVjHv914XP9Qx0ggtuO1eiZ8D3wwNhjZqFC6F1SqYNVojX7ph8e5OVewjDxpSQ~R2tIg6GGNaEGawynbiZXFembIStivg0Arj69uHuHT3RNMnSxJ08HP1PuIA3pz37kHMYtB1t6pOp6fWyC~PDE6ZUA3oIeC9OY6bZjB4n7gjN1WZ3-Wi5iYJwAYcRwJ7vXtxpP6dilP46EwqKrgFuHQ0fsJiQNTg-7pr7HIwkE3oX3ya1Cnfy6ZXnSyknG4YdyIRobugc-Ig00PrQ3n2fVn4o1DR1zLh3xA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        'https://s3-alpha-sig.figma.com/img/fb5c/589c/987da1cda150100eb0ab9efad2d1e46a?Expires=1694995200&Signature=TM61xelzGlxslTrBL54ed5eMsPBvx9xzDGbvz4dkHr1fszWOODb~Wkq7n0lVFHedZD9IMTMvoCywAaN2sog7eTudaylJnk-NDbnaO5C0YFlk3-s4nzeMbUBah2sljP1vTL6o74hmVKyQdg5mxq7LcIXAAzmbEwjGDW-6kzLY57ccwvQ7wi~d4zG8Q1XVBteTUBQYiO7n37e9dl-cqk7jEqbkoqpEc1xhjwLpCVU263dSCdB6M~7GOhO6jlklTzzr3gfTyha6nqoaCfgY6WmQ1Vvbc9cJna7VwmyCalV1b98I5107O6IEttMmKc6IXDUPYzxuZWVLyvIVLkjZ7wvArA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    ];
	return (
		<div>
            <div className='body'>
                <Slider images={images} />
                <Services />
                <Industries />
            </div>
		</div>
	);
};

export default Home;