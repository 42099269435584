import React from 'react';
import Slider from '../components/SlideComponent/Slider';
import Project from '../components/Projects/projects';
import SendMessage from '../components/DetailsPage/sendMessage';
import Subscribe from '../components/HomePage/Subscribe/subscribe';

const Projects = () => {
	return (
		<>
			<div>
				<Slider />
				<Project />
			</div>
		</>
	);
};

export default Projects;
