import "./projects.css"

const ProjectContent = [
    {
        id: 1,
        name: "Satellite Field Development",
        sub: "",
        subname: "Project",
    },
    {
        id: 2,
        name: "QIT Gas Pipeline",
        sub: "",
        subname: "Project",
    },
    {
        id: 3,
        name: "Escravos Export System",
        sub: "",
        subname: "Project",
    },
    {
        id: 4,
        name: "OSO-QIT Gas Pipeline",
        sub: "",
        subname: "Project",
    },
    {
        id: 5,
        name: "ExxonMobile BRT HP Ground",
        sub: "Flare Replacement",
        subname: "Project",
    },
    {
        id: 6,
        name: "Idoho BP-QIT CRA Pipeline",
        sub: "Replacement",
        subname: "Project",
    },
    {
        id: 7,
        name: "Usari Alternative",
        sub: "Development",
        subname: "Project",
    },
    {
        id: 8,
        name: "Edop Tranche-JV Platforms ",
        sub: "Major Structural ",
        subname: "Repair",
    },
    {
        id: 9,
        name: "WRPC QUICK FIX PROJECT",
        sub: "",
        subname: "(NNPC)",
    }
]
const Project = () =>{
    return(
        <>
            <div className="projectContainer">
                <h3>
                    Projects 
                </h3>
                
                <div>
                    <div className="row">
                        {ProjectContent.map((content) => (
                            <div className="projectColumn" key={content.id}>
                                <div className="projectCard">
                                    <h1>
                                        {content.name}  
                                    </h1>

                                    <h2>
                                        {content.sub}
                                    </h2>

                                    <p>
                                        {content.subname}
                                    </p>
                                    
                                </div> 
                            </div>
                        ))}
                    </div>
                </div>
                
            </div>
        </>
    );
};

export default Project;