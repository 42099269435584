import image1 from "../../assets/column1.png"
import image2 from "../../assets/column2.png"
import image3 from "../../assets/column3.png"
import image4 from "../../assets/column4.png"
import image5 from "../../assets/column5.png"
import image6 from "../../assets/column6.png"

const Columns = () => {
    return(
        <>
            <div className="productsColumnsContainer">

                <h1>
                    Columns & Towers, Pressure Vessels, Reactors & Tanks and Heat Exchangers
                </h1>
                <div className="topColumnSpacing">
                    <div class="productSpecialtyBG">
                        <div className="productContainerCenter">
                            <div class="row">
                                <div class="productColumn">   
                                    <div class="productTextContainer">
                                        <img src={image1} alt="" class="regularImage" />
                                        <div class="productCenteredText">
                                            <p>
                                            Gas Cooler
                                            <br/>
                                            Hot Oil Cooler
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productColumn">
                                    <div class="productTextContainer">
                                        <img src={image2} alt="" class="centeredProductImage" />
                                        <div class="productCenteredImageCont">
                                            <p>
                                            Fractionation Column
                                            <br/>
                                            Regeneration Column
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productColumn">
                                    <div class="productTextContainer">
                                        <img src={image3} alt="" class="regularImage" />
                                        <div class="productCenteredText">
                                            <p>
                                            Vacuum Receiver
                                            <br/>
                                            Tail Gas Coalescer
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                    <div class="productColumn">   
                                        <div class="productTextContainer">
                                            <img src={image4} alt="" class="regularImage" />
                                            <div class="productCenteredText">
                                                <p>
                                                Chlorine Bullets
                                                <br/>
                                                Nitrogen Bullets
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="productColumn">
                                        <div class="productTextContainer">
                                            <img src={image5} alt="" class="centeredProductImageBottom" />
                                            <div class="centeredImageContBottom">
                                                <p>
                                                Helical Baffle Exchanger
                                                <br/>
                                                Shell & Tube Heat Exchangers
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="productColumn">
                                        <div class="productTextContainer">
                                            <img src={image6} alt="" class="regularImage" />
                                            <div class="productCenteredText">
                                                <p>
                                                Stripper Column
                                                <br/>
                                                Recovery Column
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="table">
                        <div className="tableColCont">
                            <div className="row">
                                <div className="tableCol4">
                                    <h4>
                                    Pressure Vessels, Reactors & Tanks
                                    </h4>
                                    
                                    <ul>
                                        <li>
                                        Buffer Vessels
                                        </li>

                                        <li>
                                        Chlorine Reactors
                                        </li>

                                        <li>
                                        Sulphonation Reactor
                                        </li>

                                        <li>
                                        Hydrogenation Reactor
                                        </li>

                                        <li>
                                        Kettle Reactors
                                        </li>

                                        <li>
                                        Chlorine Bullets
                                        </li>

                                        <li>
                                        Nitrogen Bullets
                                        </li>

                                        <li>
                                        Knock Out Drums
                                        </li>

                                        <li>
                                        Converters
                                        </li>

                                        <li>
                                        Tail Gas Coalescer
                                        </li>

                                        <li>
                                        Air Receiver
                                        </li>

                                        <li>
                                        Hot Wells
                                        </li>

                                        <li>
                                        Vacuum Receiver
                                        </li>

                                        <li>
                                        Deaerator
                                        </li>

                                        <li>
                                        API tanks
                                        </li>
                                    </ul>
                                </div>

                                <div className="tableCol4">
                                    <h4>
                                    Columns & Towers
                                    </h4>
                                    <ul>
                                        <li>
                                        Fractionation Column
                                        </li>

                                        <li>
                                        Regeneration Column
                                        </li>

                                        <li>
                                        Distillation Column
                                        </li>

                                        <li>
                                        Oxidizer Column
                                        </li>

                                        <li>
                                        Extractor Column
                                        </li>

                                        <li>
                                        Absorber Column
                                        </li>

                                        <li>
                                        Stripper Column
                                        </li>

                                        <li>
                                        Recovery Column
                                        </li>

                                        <li>
                                        HBFC Column
                                        </li>

                                        <li>
                                        Stabilizer Column
                                        </li>

                                        <li>
                                        H2S Scrubber
                                        </li>

                                        <li>
                                        Benzol Scrubber
                                        </li>

                                        <li>
                                        Absorption Tower
                                        </li>

                                        <li>
                                        Degasser T
                                        </li>
                                    </ul>
                                </div>

                                <div className="tableCol4">
                                    <h4>
                                    Heat Exchangers
                                    </h4>

                                    <ul>
                                        <li>
                                            Heat Exchangers
                                        </li>

                                        <li>
                                            Shell & Tube Heat Exchangers 
                                        </li>
                                        
                                        <li>
                                            Helical Baffle Exchanger
                                        </li>

                                        <li>
                                            Fin Tube Type
                                        </li>

                                        <li>
                                            Corrugated Tube
                                        </li>

                                        <li>
                                            Economizer Coil
                                        </li>

                                        <li>
                                            Waste Heat Recovery Boiler
                                        </li>

                                        <li>
                                            Brine Heater
                                        </li>

                                        <li>
                                            Chlorine Evaporator
                                        </li>

                                        <li>
                                            Surface Condensers
                                        </li>

                                        <li>
                                            Process Condensate Cooler 
                                        </li>

                                        <li>
                                            Regeneration Cooler
                                        </li>

                                        <li>
                                            Lean Solvent Cooler
                                        </li>

                                        <li>
                                            Propane Refrigerant Condenser
                                        </li>

                                        <li>
                                            Lean Solution Cooler
                                        </li>

                                        <li>
                                            Lube Oil Cooler 
                                        </li>
                                    </ul>
                                </div>

                                <div className="tableCol4">
                                    <ul>
                                        <li>
                                            Economizer Coil  
                                        </li>

                                        <li>
                                            Falling Film Reboiler 
                                        </li>

                                        <li>
                                            Air Pre-heater 
                                        </li>

                                        <li>
                                            Reactor feed Heater 
                                        </li>

                                        <li>
                                            Kettle Reboiler 
                                        </li>

                                        <li>
                                            Process Condensate Cooler 
                                        </li>

                                        <li>
                                            Primary Gas Cooler
                                        </li>

                                        <li>
                                            Gas Cooler 
                                        </li>

                                        <li>
                                            Hot Oil Cooler 
                                        </li>

                                        <li>
                                            LPG Cooler
                                        </li>

                                        <li>
                                            Lube Oil Cooler 
                                        </li>

                                        <li>
                                            Steam Generating Condenser
                                        </li>

                                        <li>
                                            Vacuum Concentrator 
                                        </li>

                                        <li>
                                            Dephlagmator
                                        </li>

                                        <li>
                                            Vent Condenser
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="pagination">
                    <a className="active" href="/productsTwo">Previous</a>
                    <a className="notactive" href="/products">| &nbsp; 1  &nbsp; |</a>
                    <a className="active" href="/productsTwo">2</a>
                    <a className="notactive" href="/productsTwo">| &nbsp; Next</a>
                </div>
            </div>
        </>
    );
};

export default Columns;