/* eslint-disable react/style-prop-object */
import "./products.css";
import image1 from "../../assets/Rectangle 58.png"
import image2 from "../../assets/Rectangle 59.png"
import image3 from "../../assets/Rectangle 60.png"
import image4 from "../../assets/Rectangle 61.png"
import image5 from "../../assets/Rectangle 62-2.png"
import image6 from "../../assets/Rectangle 63.png"

const Process = () => {
    return(
        <>
            <div className="productsContainer">
                <div className="proSpacing">
                    <h1>
                        Process and Line Pipe & Fitting and Flanges
                    </h1>

                    <div class="productContainerBG">
                        <div className="productContainerCenter">
                            <div class="row">
                                <div class="productColumn">   
                                    <div class="productTextContainer">
                                        <img src={image1} alt="" class="regularImage" />
                                        <div class="productCenteredText">
                                            <p>
                                                Seamless Pipes
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productColumn">
                                    <div class="productTextContainer">
                                        <img src={image2} alt="" class="centeredProductImage" />
                                        <div class="productCenteredImageCont">
                                            <p>
                                            Forged Pipe Fittings
                                            <br/>
                                            Nuts, Bolts and Gaskets
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productColumn">
                                    <div class="productTextContainer">
                                        <img src={image3} alt="" class="regularImage" />
                                        <div class="productCenteredText">
                                            <p>
                                                Welded Pipes
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                    <div class="productColumn">   
                                        <div class="productTextContainer">
                                            <img src={image4} alt="" class="regularImage" />
                                            <div class="productCenteredText">
                                                <p>
                                                    Seamless and Welded Pipes
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="productColumn">
                                        <div class="productTextContainer">
                                            <img src={image5} alt="" class="centeredProductImageBottom" />
                                            <div class="centeredImageContBottom">
                                                <p>
                                                    Butt weld Fittings
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="productColumn">
                                        <div class="productTextContainer">
                                            <img src={image6} alt="" class="regularImage" />
                                            <div class="productCenteredText">
                                                <p>
                                                    Flanges and Spectacle Blinds
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="table">
                        <div className="tableColCont">
                            <div className="row">
                                <div className="tableCol">
                                    <ul>
                                        <li>
                                            Welded Pipes
                                        </li>
                                        
                                        <li>
                                            Seamless Pipes
                                        </li>
                                        
                                        <li>
                                            Seamless and Welded Pipes
                                        </li>
                                        
                                    </ul>
                                </div>

                                <div className="tableCol">
                                    <ul>
                                        <li>
                                        Butt weld Fittings
                                        </li>
                                        
                                        <li>
                                            Forged Pipe Fittings
                                        </li>
                                        
                                        <li>
                                            Nuts, Bolts and Gaskets
                                        </li>
                                        
                                    </ul>
                                </div>

                                <div className="tableCol">
                                    <ul>
                                        <li>
                                            Flanges and Spectacle Blinds (Blind Flange)
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Process;