/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import "./footer.css"
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import image from "../../assets/image 1.png"

const Footer = () => {
    const handleScrollToSection = (sectionId) => {
        const targetElement = document.getElementById(sectionId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };
    return(
        <>
            <div class="footer">
                <div className="footerBGOne">
                    <div className="footerSpace">
                        <div className="footerBGTwo">
                            <div class="row">
                                <div class="footerColumn abt">
                                    <img src={image} />
                                    <h3>
                                        We partner with organizations in the Oil and Gas industry to help them maintain profitability and drive efficiency by providing specific solutions to all their procurement and logistic needs. 
                                    </h3>
                                </div>

                                <div class="footerColumn links">
                                    <div className="space">
                                        <h2>About Us</h2>
                                        <Link to="/about#scroll500">
                                            Who we are
                                        </Link>

                                        <Link to="/about#scroll1200">
                                            Our mission
                                        </Link>  

                                        <Link to="/about#scroll1500">
                                            Our Vision
                                        </Link> 

                                        <Link to="/about#scroll2000">
                                            Our core value
                                        </Link> 
                                    </div>
                                </div>

                                <div class="footerColumn links">
                                    <div className="spaceServices">
                                        <h2>Services</h2>
                                        <a>
                                            Sourcing
                                        </a>

                                        <a>
                                            Execution
                                        </a>

                                        <a>
                                            Logistics
                                        </a>

                                        <a>
                                            SCM
                                        </a>

                                        <a>
                                            Project management
                                        </a>

                                        <a>
                                            Products
                                        </a>
                                    </div>
                                </div>

                                <div class="footerColumn cntc">
                                    <h2>Contact Us</h2>
                                    <p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 20 16" fill="none">
                                            <path d="M17.334 0.666748H2.66732C1.65898 0.666748 0.843151 1.49175 0.843151 2.50008L0.833984 13.5001C0.833984 14.5084 1.65898 15.3334 2.66732 15.3334H17.334C18.3423 15.3334 19.1673 14.5084 19.1673 13.5001V2.50008C19.1673 1.49175 18.3423 0.666748 17.334 0.666748ZM17.334 4.33341L10.0007 8.91675L2.66732 4.33341V2.50008L10.0007 7.08341L17.334 2.50008V4.33341Z" fill="white"/>
                                        </svg>

                                        <b>Email</b>: info@graceprocurementservices.com
                                        <br/>
                                        <span>Info.graceprocurement@gmail.com</span> 
                                    </p>

                                    <p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M4.06833 7.89083C5.38833 10.485 7.515 12.6025 10.1092 13.9317L12.1258 11.915C12.3733 11.6675 12.74 11.585 13.0608 11.695C14.0875 12.0342 15.1967 12.2175 16.3333 12.2175C16.8375 12.2175 17.25 12.63 17.25 13.1342V16.3333C17.25 16.8375 16.8375 17.25 16.3333 17.25C7.72583 17.25 0.75 10.2742 0.75 1.66667C0.75 1.1625 1.1625 0.75 1.66667 0.75H4.875C5.37917 0.75 5.79167 1.1625 5.79167 1.66667C5.79167 2.8125 5.975 3.9125 6.31417 4.93917C6.415 5.26 6.34167 5.6175 6.085 5.87417L4.06833 7.89083Z" fill="white"/>
                                        </svg>
                                        <b>Phone</b>: +2349133466685
                                        <br/>
                                    </p>

                                    <p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
                                            <path d="M7.00065 0.833313C3.45315 0.833313 0.583984 3.70248 0.583984 7.24998C0.583984 12.0625 7.00065 19.1666 7.00065 19.1666C7.00065 19.1666 13.4173 12.0625 13.4173 7.24998C13.4173 3.70248 10.5482 0.833313 7.00065 0.833313ZM7.00065 9.54165C5.73565 9.54165 4.70898 8.51498 4.70898 7.24998C4.70898 5.98498 5.73565 4.95831 7.00065 4.95831C8.26565 4.95831 9.29232 5.98498 9.29232 7.24998C9.29232 8.51498 8.26565 9.54165 7.00065 9.54165Z" fill="white"/>
                                        </svg>
                                        <b>
                                            Address
                                        </b>: 
                                        39 Alfred Rewane Road Ikoyi,
                                        <br/>
                                        <div>
                                        Lagos, Nigeria.
                                        </div>
                                    </p>
                                </div>
                            </div>
                            
                            <div class="footerNav">
                                <div class="footerNav-centered">
                                    <a href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>

                                    <a href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M23 2.99998C22.0424 3.67546 20.9821 4.19209 19.86 4.52999C19.2577 3.8375 18.4573 3.34668 17.567 3.12391C16.6767 2.90115 15.7395 2.95718 14.8821 3.28444C14.0247 3.6117 13.2884 4.19439 12.773 4.9537C12.2575 5.71302 11.9877 6.61232 12 7.52998V8.52998C10.2426 8.57555 8.50127 8.1858 6.93101 7.39543C5.36074 6.60506 4.01032 5.43862 3 3.99998C3 3.99998 -1 13 8 17C5.94053 18.398 3.48716 19.0989 1 19C10 24 21 19 21 7.49998C20.9991 7.22144 20.9723 6.94358 20.92 6.66999C21.9406 5.66348 22.6608 4.3927 23 2.99998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>

                                    <a href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M6 9H2V21H6V9Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>

                                    <a href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M15.9997 11.37C16.1231 12.2022 15.981 13.0522 15.5935 13.799C15.206 14.5458 14.5929 15.1514 13.8413 15.5297C13.0898 15.9079 12.2382 16.0396 11.4075 15.9059C10.5768 15.7723 9.80947 15.3801 9.21455 14.7852C8.61962 14.1902 8.22744 13.4229 8.09377 12.5922C7.96011 11.7615 8.09177 10.9099 8.47003 10.1584C8.84829 9.40685 9.45389 8.79374 10.2007 8.40624C10.9475 8.01874 11.7975 7.87658 12.6297 8C13.4786 8.12588 14.2646 8.52146 14.8714 9.1283C15.4782 9.73515 15.8738 10.5211 15.9997 11.37Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.5 6.5H17.51" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>
                                </div>

                                <a href="#"></a>
                            </div>

                            <div className="reservedRights">
                                <p>
                                ©
                                    2023 Grace Procurement Services Limited. All rights reserved
                                    |
                                    Designed by ADSOS
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;