import './Slider.css';

function SlideContent() {
  return (
    <div className='slideBg'>
        <div className='slideContent'>
            <div className='slideContentText'>
                <h2>
                    Our core business activities are technical procurement and logistics service in oil and gas companies. Grace Procurement Services Limited mainly operates in Nigeria and partners with manufacturing companies across the globe
                </h2>

                <div className='cardContainer'>
                    <div className='row'>
                        <div className='column'>
                            <div className='card'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path d="M33.3335 10.0001H20.0002L16.6668 6.66675H6.66683C4.8335 6.66675 3.35016 8.16675 3.35016 10.0001L3.3335 30.0001C3.3335 31.8334 4.8335 33.3334 6.66683 33.3334H33.3335C35.1668 33.3334 36.6668 31.8334 36.6668 30.0001V13.3334C36.6668 11.5001 35.1668 10.0001 33.3335 10.0001ZM23.3335 26.6667H10.0002V23.3334H23.3335V26.6667ZM30.0002 20.0001H10.0002V16.6667H30.0002V20.0001Z" fill="white"/>
                                </svg>
                                <h4>
                                    Sourcing
                                </h4>
                            </div>
                        </div>

                        <div className='column'>
                            <div className='card'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path d="M24.9999 36.6779H8.33327V33.3445H24.9999V36.6779ZM36.6666 6.66659L28.7749 16.5916L28.4083 17.0416L19.0249 29.0249C18.7155 29.336 18.3478 29.5831 17.9428 29.7518C17.5378 29.9206 17.1034 30.0078 16.6647 30.0084C16.2259 30.009 15.7913 29.923 15.3858 29.7554C14.9804 29.5878 14.6119 29.3418 14.3017 29.0315C13.9914 28.7213 13.7454 28.3528 13.5778 27.9473C13.4102 27.5419 13.3242 27.1073 13.3248 26.6685C13.3254 26.2297 13.4126 25.7954 13.5814 25.3904C13.7501 24.9854 13.9971 24.6176 14.3083 24.3083L36.6666 6.66659ZM4.4081 12.0574C2.0012 15.9132 1.13572 20.5344 1.98419 24.9999H3.33327V23.3333C3.31543 20.0517 4.29819 16.8427 6.15055 14.1339L4.4081 12.0574ZM19.9999 3.33325C17.4511 3.33291 14.9304 3.86606 12.6 4.8984C10.2696 5.93075 8.18107 7.43943 6.46875 9.32742L8.37494 11.5991C10.6376 9.519 13.5931 8.35489 16.6666 8.33325C19.7567 8.35487 22.727 9.53064 24.9948 11.6298L30.9129 6.95977C27.7619 4.60496 23.9336 3.33278 19.9999 3.33325ZM31.3834 18.6661L30.9944 19.1454L29.7812 20.6948C29.9232 21.5672 29.9963 22.4494 29.9999 23.3333V24.9999H38.0157C38.8068 20.8292 38.1094 16.513 36.0451 12.8037L31.3834 18.6661Z" fill="white"/>
                                </svg>
                                <h4>
                                    Execution
                                </h4>
                            </div>
                        </div>

                        <div className='column'>
                            <div className='card'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <g clip-path="url(#clip0_0_518)">
                                        <path d="M23.3333 11.6667H10V15.0001H23.3333V11.6667Z" fill="white"/>
                                        <path d="M30 18.3333H10V21.6666H30V18.3333Z" fill="white"/>
                                        <path d="M14.9833 25H10V28.3333H14.9833V25Z" fill="white"/>
                                        <path d="M23.3335 5L18.3335 0V3.33333H6.66683C5.78277 3.33333 4.93493 3.68452 4.30981 4.30964C3.68469 4.93477 3.3335 5.78261 3.3335 6.66667V33.3333C3.3335 34.2174 3.68469 35.0652 4.30981 35.6904C4.93493 36.3155 5.78277 36.6667 6.66683 36.6667H13.3335V33.3333H6.66683V6.66667H18.3335V10L23.3335 5Z" fill="white"/>
                                        <path d="M16.6665 34.9999L21.6665 39.9999V36.6666H33.3332C34.2172 36.6666 35.0651 36.3154 35.6902 35.6903C36.3153 35.0652 36.6665 34.2173 36.6665 33.3333V6.66659C36.6665 5.78253 36.3153 4.93468 35.6902 4.30956C35.0651 3.68444 34.2172 3.33325 33.3332 3.33325H26.6665V6.66659H33.3332V33.3333H21.6665V29.9999L16.6665 34.9999Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_518">
                                        <rect width="40" height="40" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h4>
                                    Logistics
                                </h4>
                            </div>
                        </div>

                        <div className='column'>
                            <div className='card'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path d="M31.6668 11.6666C31.6668 9.83325 30.1668 8.33325 28.3335 8.33325H23.3335V11.6666H28.3335V16.0833L22.5335 23.3333H16.6668V14.9999H10.0002C6.31683 14.9999 3.3335 17.9833 3.3335 21.6666V26.6666H6.66683C6.66683 29.4333 8.90016 31.6666 11.6668 31.6666C14.4335 31.6666 16.6668 29.4333 16.6668 26.6666H24.1335L31.6668 17.2499V11.6666ZM11.6668 28.3333C10.7502 28.3333 10.0002 27.5833 10.0002 26.6666H13.3335C13.3335 27.5833 12.5835 28.3333 11.6668 28.3333Z" fill="white"/>
                                    <path d="M16.6668 10H8.3335V13.3333H16.6668V10Z" fill="white"/>
                                    <path d="M31.6665 21.6667C28.8998 21.6667 26.6665 23.9001 26.6665 26.6667C26.6665 29.4334 28.8998 31.6667 31.6665 31.6667C34.4332 31.6667 36.6665 29.4334 36.6665 26.6667C36.6665 23.9001 34.4332 21.6667 31.6665 21.6667ZM31.6665 28.3334C30.7498 28.3334 29.9998 27.5834 29.9998 26.6667C29.9998 25.7501 30.7498 25.0001 31.6665 25.0001C32.5832 25.0001 33.3332 25.7501 33.3332 26.6667C33.3332 27.5834 32.5832 28.3334 31.6665 28.3334Z" fill="white"/>
                                </svg>
                                <h4>
                                    SCM
                                </h4>
                            </div>
                        </div>

                        <div className='column'>
                            <div className='card'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path d="M31.6667 6.66655H24.7C24.3586 5.69237 23.723 4.84833 22.881 4.25117C22.039 3.65401 21.0323 3.33325 20 3.33325C18.9677 3.33325 17.961 3.65401 17.119 4.25117C16.277 4.84833 15.6414 5.69237 15.3 6.66655H8.33333C7.45007 6.66912 6.60371 7.02113 5.97914 7.64569C5.35458 8.27026 5.00256 9.11662 5 9.99988V33.3332C5.00258 34.2165 5.3546 35.0628 5.97916 35.6874C6.60372 36.312 7.45007 36.664 8.33333 36.6665H31.6667C32.5499 36.664 33.3963 36.3119 34.0208 35.6874C34.6454 35.0628 34.9974 34.2165 35 33.3332V9.99988C34.9974 9.11662 34.6454 8.27028 34.0208 7.64571C33.3963 7.02115 32.5499 6.66913 31.6667 6.66655ZM20 6.66655C20.3296 6.66655 20.6519 6.7643 20.926 6.94744C21.2 7.13057 21.4137 7.39087 21.5398 7.69541C21.6659 7.99996 21.6989 8.33507 21.6346 8.65837C21.5703 8.98167 21.4116 9.27864 21.1785 9.51173C20.9454 9.74482 20.6485 9.90355 20.3252 9.96786C20.0018 10.0322 19.6667 9.99916 19.3622 9.87302C19.0576 9.74687 18.7974 9.53325 18.6142 9.25917C18.4311 8.98509 18.3333 8.66285 18.3333 8.33322C18.3346 7.89158 18.5106 7.4684 18.8229 7.15612C19.1352 6.84384 19.5584 6.66783 20 6.66655ZM16.6667 14.9999L21.3178 19.656L25.5159 15.4579L23.3333 13.3332H30V19.9999L27.8729 17.8149L21.3204 24.3726L16.6667 19.7161L12.3571 24.0227L10 21.6666L16.6667 14.9999ZM31.6667 31.6665H8.33333V28.3332H31.6667V31.6665Z" fill="white"/>
                                </svg>
                                <h4>
                                    Project MGT
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default SlideContent;