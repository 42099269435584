import React, { useEffect } from 'react';
import Slider from '../components/SlideComponent/Slider';
import Process from '../components/ProductsPage/process';
import Valves from '../components/ProductsPage/valves';
import Structural from '../components/ProductsPage/stuctural';
import SendMessage from '../components/DetailsPage/sendMessage';
import Subscribe from '../components/HomePage/Subscribe/subscribe';
import "../App.css"
import Electrical from '../components/ProductsPage/electrical';

const Products = () => {
	useEffect(() => {
		if (window.location.hash === '#scroll600') {
		  window.scrollTo(0, 600);
		}
		if (window.location.hash === '#scroll1200') {
			window.scrollTo(0, 1700);
		  }
		  if (window.location.hash === '#scroll1500') {
			window.scrollTo(0, 2200);
		  }
		  if (window.location.hash === '#scroll2000') {
			window.scrollTo(0, 3200);
		  }
	}, []);
	return (
		<div>
			<Slider />

			<h1 className='productsHeader'>
				What We Procure
			</h1>

			<div className='productsPage'>
				<Process />
				<Structural />
				<br/>
				<Valves />
				<Electrical />
			</div>
		</div>
	);
};

export default Products;
