import React from 'react';
import Slider from '../components/SlideComponent/Slider';
import Services from '../components/DetailsPage/services';
import Quality from '../components/DetailsPage/quality';
import Procure from '../components/DetailsPage/procure';
import SendMessage from '../components/DetailsPage/sendMessage';
import Choose from '../components/DetailsPage/choose';
import Subscribe from '../components/HomePage/Subscribe/subscribe';

const HomePage = () => {
	return (
          <>
            <Slider />
            <Services />
            <Quality />
            <Procure />
            <Choose />
          </>
	);
};

export default HomePage;
