import React from 'react';
import Slider from '../components/SlideComponent/Slider';
import Contacts from '../components/Contact/contacts';
import Map from '../components/Contact/map';
import Subscribe from '../components/HomePage/Subscribe/subscribe';
import SendMessage from '../components/DetailsPage/sendMessage';
import "../App.css"

const Contact = () => {
	return (
		<div>
			<Slider />
			<Contacts />
			<Map />

			<div className='contactSubscribe'>
				<SendMessage />
				<Subscribe />
			</div>
		</div>
	);
};

export default Contact;
