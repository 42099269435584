import "./about.css"

const  values = [
    {
        id: 1,
        name: "Honesty",
    },
    {
        id: 2,
        name: "Team Work",
    },
    {
        id: 3,
        name: "Trust",
    },
    {
        id: 4,
        name: "Integrity",
    },
    {
        id: 5,
        name: "Innovation",
    },
    {
        id: 6,
        name: "Growth",
    }
]
const CoreValue = () =>{
    return(
        <>
            <h1 className="coreValuesBodyHead">
                Core Value
            </h1>
            <div className="coreValuesBody">
                {values.map((value) => (
                    <div key={value.id}>
                        <div class="rotating-border rotating-border--blue">
                            {value.name}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CoreValue;