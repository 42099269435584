import "./procure.css";
import image0 from "../../assets/Rectangle 34.png"
import image01 from "../../assets/Rectangle 35.png"
import image1 from "../../assets/Rectangle 194.png"
import image2 from "../../assets/Rectangle 195.png"
import image3 from  "../../assets/Rectangle 200.png"
import image4 from  "../../assets/Rectangle 201.png"
import image5 from  "../../assets/Rectangle 203.png"
import image6 from  "../../assets/Rectangle 205.png"

const cards = [
    {
      id: 1,
      name: "Process and Line Pipe & Fitting and Flanges",
      imageSrc: image0,
      href: "/products#scroll600"
    },
  
    {
      id: 2,
      name: "Valves and Actuators Packages",
      imageSrc: image01,
      href: "/products#scroll1500"
    },

    {
        id: 3,
        name: "Structural Steel Materials",
        imageSrc: image1,
        href: "/products#scroll1200"
    },

    {
      id: 4,
      name: "Specialty Piping Products ",
      imageSrc: image2,
      href: "/productsTwo#scroll600"
    },

    {
      id: 5,
      name: "Electrical & Instrumentation",
      imageSrc: image3,
      href: "/products#scroll2000"
    },

    {
        id: 6,
        name: "Columns & Towers",
        imageSrc: image4,
        href: "/productsTwo#scroll1200"
    },

    {
        id: 7,
        name: "Pressure Vessels, Reactors & Tanks",
        imageSrc: image5,
        href: "/productsTwo#scroll1200"
    },

    {
        id: 8,
        name: "Heat Exchangers",
        imageSrc: image6,
        href: "/productsTwo#scroll1200"
    },
];

const Procure = () => {
    return(
        <>
            <div className="procureContainer">
                <h1>
                    What We Procure
                </h1>

                    <div className="row">
                        {cards.map((card) => (
                            <div className="procureColumn" key={card.id}>
                                <a href={card.href}>
                                    <div className="procureCard">
                                        <div class="procureImgContainer">
                                            <img
                                                src={card.imageSrc}
                                                alt={card.imageAlt}
                                                className="procureImage"
                                            />
                                            <div class="imageOverlay">
                                                <p>
                                                    {card.name}  
                                                </p>
                                            </div>
                                        </div>

                                        <p>
                                        {card.name}  
                                        </p>
                                        
                                    </div> 
                                </a>
                            </div>
                        ))}
                    </div>
            </div>
        </>
    );
};

export default Procure;