/* eslint-disable jsx-a11y/alt-text */
import "./service.css";
import image from "../../assets/Rectangle 33-2.png"
const Services = () => {

  return (
    <div className="detailsBody">
        <div className="row">
            <div className="servicescolumn">
                <h1>
                    Our Services
                </h1>

                <p>
                    Our organization is comprised of a combination of intelligent professionals, financial resources, clearly defined operating procedures,
                    resourceful risk management tools and firm management structure & control. The fusion of our smart team members and our
                    longstanding relationship/partnership with the industries approved Original Equipment Manufacturers (OEM) and Suppliers to deliver on our promises satisfactorily.
                </p>

                <a href="/services">
                    <span>
                        See all services

                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 14" fill="none">
                            <path d="M1 13L7 7L1 1" stroke="#0C91BB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                </a>
            </div>

            <div className="servicescolumn">
                <img src={image} />
            </div>
        </div>
    </div>
  );
};

export default Services;

