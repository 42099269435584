import "./products.css"
import image1 from "../../assets/structural1.png"
import image2 from "../../assets/structural2.png"
import image3 from "../../assets/structural3.png"

const Structural = () => {
    return(
        <>
            <div className="productsContainer">
                <div className="topSpacingStructural">
                    <h1>
                        Structural Steel Materials
                    </h1>

                    <div class="productStructuralElectricBG">
                        <div className="productContainerCenter">
                            <div class="row">
                                <div class="productStructsColumn">   
                                    <div class="productTextContainer">
                                        <img src={image1} alt="" class="regularStructuralImage" />
                                        <div class="productTextStruc">
                                            <p>
                                                Seamless and Welded Tubulars
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productStructsColumn">   
                                    <div class="productTextContainer">
                                        <img src={image2} alt="" class="centerStructuralImage" />
                                        <div class="productCenteredTextStruc">
                                            <p>
                                                Section Beams and Channels
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div class="productStructsColumn">
                                    <div class="productTextContainer">
                                        <img src={image3} alt="" class="regularStructuralImage" />
                                        <div class="productTextStruc">
                                            <p>
                                                Hot Rolled Steel Plates
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="structuraltable">
                        <div className="tableColCont">
                            <div className="row">
                                <div className="tableCol">
                                    <ul>
                                        <li>
                                            Hot Rolled Steel Plates
                                        </li>
                                    </ul>
                                </div>

                                <div className="tableCol">
                                    <ul>
                                        <li>
                                            Seamless and Welded Tubulars
                                        </li>
                                    </ul>
                                </div>

                                <div className="tableCol">
                                    <ul>
                                        <li>
                                            Section Beams, Columns and Channels
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Structural;