import './headSlider.css';
import CounterPerc from './counter';
import CounterNumber from './counterNumber';

function SlideContent() {
  return (
    <div className='slideHomeContent'>
        <div className='slideHomeContentText'>
            <h1>
                GRACE PROCUREMENT SERVICES LIMITED
            </h1>

            <h3>
                Efficient Procurement Solutions For Seamless Supply Chain Management
            </h3>

            <h5>
                We are a modern procurement service company engaged in technical procurement and logistic to organizations in the Oil and Gas Industry in Nigeria and across the globe.  
            </h5>

            <a href='/contact'>
                <button>
                    Get In Touch
                </button>
            </a>

            <div className="row">
                <div className='statisticsContainer'>
                    <div className="statistics">
                        <CounterPerc />

                        <p>
                            Effective
                        </p>
                    </div>

                    <div className="statistics">
                        <div className='centerStatisticCard'>
                            <CounterNumber />
                            <p>
                                Team Experience
                            </p>
                        </div>
                    </div>

                    <div className="statistics">
                        <CounterPerc />
                        <p>
                            Customer Service
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
  );
}

export default SlideContent;