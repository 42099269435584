import "./about.css"
const Apply = () =>{
    return(
        <>
            <div class="jobApply">
                <form action="https://formsubmit.co/nfo@graceprocurementservices.com">
                    <h1>
                        Apply Now
                    </h1>
                    <label for="country">
                        Attach CV/Resume
                    </label>
                    <select id="country" name="country">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                    </select>
                    
                    <label for="fname">First Name</label>
                    <input type="text" id="fname" name="firstname" />

                    <label for="lname">Last Name</label>
                    <input type="text" id="lname" name="lastname" />
                    
                    <label for="email">Email</label>
                    <input type="email" id="fname" name="firstname" />
                    
                    <label for="email">Additional information</label>
                    <textarea id="subject" name="subject" />
                
                    <input type="submit" value="Submit" />
                </form>
            </div>

        </>
    );
};

export default Apply;