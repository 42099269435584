/* eslint-disable jsx-a11y/alt-text */
import "./about.css";
import image1 from "../../assets/Group 42.png";
import image2 from "../../assets/Group 43.png"

const Mission = () =>{
    return(
        <>
            <div className="missionContainer">
                <h1>
                    Mission, Vision, Goal & Core Value
                </h1>

                <div className="missionImage">
                    <img src={image1} className="leftMissionImg" />

                    <div className="missionText">
                        <span>
                            Our Mission
                        </span>

                        <p>
                            Our mission at GPS is to achieve excellence through efficient provision of agreed Products and Services within budgets, on time, and safely as specified by our client.
                        </p>
                    </div>
                </div>

                <div className="missionImageRight">
                    <div className="missionTextRight">
                        <span>
                            Our Vision
                        </span>

                        <p>
                            To become a foremost seek after procurement service provider to the oil & Gas industry and Power sector with capacity of delivering excellent service and value.
                        </p>

                        <img src={image2} className="rightMissionImg" />
                    </div>

                </div>

                <div className="missionImageLeft">
                    <div className="missionTextRight">
                        <span>
                            Our Goal
                        </span>

                        <p>
                            To meet the expectation of every client by offering outstanding customer service, increased flexibility and great value, thus optimizing system functionality and improving operation efficiency.
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Mission;