/* eslint-disable jsx-a11y/alt-text */
import './industries.css';
import image1 from "../../../assets/Rectangle36.png"
import image2 from "../../../assets/Rectangle35.png"
import image3 from "../../../assets/Rectangle37.png"

function Industries() {
  return (
      <>
        <div className='industriesContainer'>
            <h1>
                Industries
            </h1>

            <div className='row'>
                <div className='industries'>
                    <img src={image1} />
                    <p>
                        Oil & Gas
                    </p>
                </div>

                <div className='industries'>
                    <img src={image2} />
                    <p>
                        Power
                    </p>
                </div>

                <div className='industries'>
                    <img src={image3} />
                    <p>
                        Communication
                    </p>
                </div>
            </div>
        </div>
      </>
  )
}

export default Industries;