import "./quality.css";
import image from "../../assets/image 3.png"

const Quality = () =>{
    return(
        <>
            <div className="qualityDetailsCont">
                <h1>
                    Quality
                </h1>

                <p>
                    We are an ISO 9001:2015 certified company which goes to show our compliance with best practice and commitment to client satisfaction and service delivery.
                    We are committed to ensuring customer satisfaction by continually improving processes and services to ensure that we consistently
                    meet our customers’ expectations.
                </p>

                <div className="qualityDetailsBackground">
                    <div className="qualityColumnCont">
                        <div className="row">
                            <div className="qualityColumn">
                                <div className="qualityCard">
                                    <p>
                                        It is our policy to:
                                    </p>

                                    <ul>
                                        <li>
                                            Provide internal and external customers with quality services that consistently meet expectations.
                                        </li>

                                        <li>
                                            Complying with all customers, regulatory and organizational requirements.
                                        </li>

                                        <li>
                                            Hiring the best people and training those people in our system to execute our processes flawlessly.
                                        </li>

                                        <li>
                                            Effectively managing operations to provide excellent service delivery.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="qualityColumn">
                                <div className="qualityCard">
                                    <p>
                                        It is our objective to:
                                    </p>

                                    <ul>
                                        <li>
                                            Understand the needs of our clients.
                                        </li>

                                        <li>
                                            Satisfy clients’ needs with reliable solutions based on applicable international standards.
                                        </li>

                                        <li>
                                            Train employees continuously to ensure our people are empowered to meet clients’ needs; and
                                        </li>

                                        <li>
                                            Improve our quality control processes to enhance service reliability, cost competitiveness and customer satisfaction through periodic review.
                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <img src={image} alt="" className="qualityCertifiedImage" />
            </div>
        </>
    );
};

export default Quality;