import "./services.css";
const OurServices = () => {
    return(
        <>
            <div className="row">
                <div className="solutionCol">
                    <div className="textSolutionCol">
                        <h1 className="floating-text">
                            Our Services
                        </h1>

                        <p className="floating-text">
                            Our corporation is comprised of a combination of intelligent professionals, financial resources, clearly defined operating procedures,
                            resourceful risk management tools and firm management structure & control. The fusion of our smart team members and our
                            longstanding relationship/partnership with the industries approved Original Equipment Manufacturers (OEM) and Suppliers to deliver on our promises satisfactorily.
                            <br/><br/>
                            We are aware of the complexity of procurement and how it can negatively affect your core business, schedule, project and even your inflow. We are committed to delivering professional and best practice service that will increase business values to our clients and its shareholders. We will work with you to identify opportunities that will yield the most cost saving to your organization.
                        </p>
                    </div>
                </div>

                <div className="solutionCol">
                    <div className="servicesImgCard">
                        <div className="servicesImgCont"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OurServices;