import React, { useState, useEffect } from 'react';
import './Slider.css';
import SlideContent from './SliderCont';
import shuffle from 'lodash/shuffle';

import image1 from "../../assets/Rectanglegps3.png";
import image2 from "../../assets/Rectanglegps4.png";
import image3 from "../../assets/Rectanglegps5.png";

const images = shuffle([image1, image2, image3]);

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="slideshow-container">
      {images.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
        >
          <img src={image} alt={`Slide ${index}`} className="slide-image"/>
          <SlideContent />
        </div>
      ))}
      <div className="dot-container">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;
