import './service.css';
import image1 from "../../../assets/5350015 1.png"
import image2 from "../../../assets/5155985 1.png"
import image3 from "../../../assets/4363531 1.png"
import image4 from "../../../assets/9402077 1.png"
import image5 from "../../../assets/1087927 1.png"

function Icons() {
    return (
        <>
            <div className='svgContainerContent'>
                <div className='row'>
                    <div className='iconCont'>
                        <div className="svg-container">
                            <svg class="background-svg" xmlns="http://www.w3.org/2000/svg" width="104" height="110" viewBox="0 0 104 110" fill="none">
                                <g filter="url(#filter0_d_156_2463)">
                                    <path d="M38 2.7735C44.188 -0.799153 51.812 -0.799154 58 2.7735L81.3013 16.2265C87.4893 19.7992 91.3013 26.4017 91.3013 33.547V60.453C91.3013 67.5983 87.4893 74.2008 81.3013 77.7735L58 91.2265C51.812 94.7992 44.188 94.7992 38 91.2265L14.6987 77.7735C8.51071 74.2008 4.69873 67.5983 4.69873 60.453V33.547C4.69873 26.4017 8.51071 19.7992 14.6987 16.2265L38 2.7735Z" fill="#0C91BB"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d_156_2463" x="0.699219" y="0.0939941" width="102.602" height="109.812" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="4" dy="8"/>
                                    <feGaussianBlur stdDeviation="4"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_2463"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_2463" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>

                            <img className="overlapping-image" src={image1} alt="" />

                            <div className="subcaption">
                                Sourcing
                            </div>
                        </div>
                    </div>

                    <div className='iconCont'>
                        <div className="svg-container">
                            <svg class="background-svg" xmlns="http://www.w3.org/2000/svg" width="104" height="110" viewBox="0 0 104 110" fill="none">
                                <g filter="url(#filter0_d_156_2463)">
                                    <path d="M38 2.7735C44.188 -0.799153 51.812 -0.799154 58 2.7735L81.3013 16.2265C87.4893 19.7992 91.3013 26.4017 91.3013 33.547V60.453C91.3013 67.5983 87.4893 74.2008 81.3013 77.7735L58 91.2265C51.812 94.7992 44.188 94.7992 38 91.2265L14.6987 77.7735C8.51071 74.2008 4.69873 67.5983 4.69873 60.453V33.547C4.69873 26.4017 8.51071 19.7992 14.6987 16.2265L38 2.7735Z" fill="#0C91BB"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d_156_2463" x="0.699219" y="0.0939941" width="102.602" height="109.812" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="4" dy="8"/>
                                    <feGaussianBlur stdDeviation="4"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_2463"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_2463" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>

                            <img className="overlapping-image" src={image2} alt="" />

                            <div className="subcaption">
                                Execution
                            </div>
                        </div>
                    </div>

                    <div className='iconCont'>
                        <div className="svg-container">
                            <svg class="background-svg" xmlns="http://www.w3.org/2000/svg" width="104" height="110" viewBox="0 0 104 110" fill="none">
                                <g filter="url(#filter0_d_156_2463)">
                                    <path d="M38 2.7735C44.188 -0.799153 51.812 -0.799154 58 2.7735L81.3013 16.2265C87.4893 19.7992 91.3013 26.4017 91.3013 33.547V60.453C91.3013 67.5983 87.4893 74.2008 81.3013 77.7735L58 91.2265C51.812 94.7992 44.188 94.7992 38 91.2265L14.6987 77.7735C8.51071 74.2008 4.69873 67.5983 4.69873 60.453V33.547C4.69873 26.4017 8.51071 19.7992 14.6987 16.2265L38 2.7735Z" fill="#0C91BB"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d_156_2463" x="0.699219" y="0.0939941" width="102.602" height="109.812" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="4" dy="8"/>
                                    <feGaussianBlur stdDeviation="4"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_2463"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_2463" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>

                            <img className="overlapping-image" src={image3} alt="" />

                            <div className="subcaption">
                                Logistics
                            </div>
                        </div>
                    </div>

                    <div className='iconCont'>
                        <div className="svg-container">
                            <svg class="background-svg" xmlns="http://www.w3.org/2000/svg" width="104" height="110" viewBox="0 0 104 110" fill="none">
                                <g filter="url(#filter0_d_156_2463)">
                                    <path d="M38 2.7735C44.188 -0.799153 51.812 -0.799154 58 2.7735L81.3013 16.2265C87.4893 19.7992 91.3013 26.4017 91.3013 33.547V60.453C91.3013 67.5983 87.4893 74.2008 81.3013 77.7735L58 91.2265C51.812 94.7992 44.188 94.7992 38 91.2265L14.6987 77.7735C8.51071 74.2008 4.69873 67.5983 4.69873 60.453V33.547C4.69873 26.4017 8.51071 19.7992 14.6987 16.2265L38 2.7735Z" fill="#0C91BB"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d_156_2463" x="0.699219" y="0.0939941" width="102.602" height="109.812" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="4" dy="8"/>
                                    <feGaussianBlur stdDeviation="4"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_2463"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_2463" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>

                            <img className="overlapping-image" src={image4} alt="" />

                            <div className="subcaption">
                                Supply Chain
                            </div>
                        </div>
                    </div>

                    <div className='iconCont'>
                        <div className="svg-container">
                            <svg class="background-svg" xmlns="http://www.w3.org/2000/svg" width="104" height="110" viewBox="0 0 104 110" fill="none">
                                <g filter="url(#filter0_d_156_2463)">
                                    <path d="M38 2.7735C44.188 -0.799153 51.812 -0.799154 58 2.7735L81.3013 16.2265C87.4893 19.7992 91.3013 26.4017 91.3013 33.547V60.453C91.3013 67.5983 87.4893 74.2008 81.3013 77.7735L58 91.2265C51.812 94.7992 44.188 94.7992 38 91.2265L14.6987 77.7735C8.51071 74.2008 4.69873 67.5983 4.69873 60.453V33.547C4.69873 26.4017 8.51071 19.7992 14.6987 16.2265L38 2.7735Z" fill="#0C91BB"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d_156_2463" x="0.699219" y="0.0939941" width="102.602" height="109.812" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="4" dy="8"/>
                                    <feGaussianBlur stdDeviation="4"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_2463"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_2463" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>

                            <img className="overlapping-image" src={image5} alt="" />
                            
                            <div className="subcaption">
                                Project Management
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Icons;