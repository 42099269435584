import "./products.css"
import image1 from "../../assets/specialty1.png"
import image2 from "../../assets/specialty2.png"
import image3 from "../../assets/specialty3.png"
import image4 from "../../assets/specialty4.png"
import image5 from "../../assets/specialty5.png"
import image6 from "../../assets/specialty6.png"

const Specialty = () => {
    return(
        <>
            <div className="productsSpecialtyContainer">

                <h1>
                Specialty Piping Products 
                </h1>

                <div className="topSpecSpacing">

                    <div class="productSpecialtyBG">
                        <div className="productContainerCenter">
                            <div class="row">
                                <div class="productColumn">   
                                    <div class="productTextContainer">
                                        <img src={image1} alt="" class="regularImage" />
                                        <div class="productCenteredText">
                                            <p>
                                            Filters
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productColumn">
                                    <div class="productTextContainer">
                                        <img src={image2} alt="" class="centeredProductImage" />
                                        <div class="productCenteredImageCont">
                                            <p>
                                            Sight Glasses and Level Gauges
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productColumn">
                                    <div class="productTextContainer">
                                        <img src={image3} alt="" class="regularImage" />
                                        <div class="productCenteredText">
                                            <p>
                                            Strainers
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                    <div class="productColumn">   
                                        <div class="productTextContainer">
                                            <img src={image4} alt="" class="regularImage" />
                                            <div class="productCenteredText">
                                                <p>
                                                Flame Arrestors
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="productColumn">
                                        <div class="productTextContainer">
                                            <img src={image5} alt="" class="centeredProductImageBottom" />
                                            <div class="centeredImageContBottom">
                                                <p>
                                                Dished Ends
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="productColumn">
                                        <div class="productTextContainer">
                                            <img src={image6} alt="" class="regularImage" />
                                            <div class="productCenteredText">
                                                <p>
                                                Tank Safety Equipment
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="table">
                        <div className="tableColCont">
                            <div className="row">
                                <div className="tableCol">

                                    <h4>
                                    Filters
                                    </h4>
                                    <ul>
                                        <li>
                                        Gas filter separators
                                        </li>
                                        
                                        <li>
                                        Liquid / Gas coalescing filters
                                        </li>
                                        
                                        <li>
                                        Liquid particulate filters
                                        </li>

                                        <li>
                                        Nuclear condensate filters
                                        </li>

                                        <li>
                                        Simplex filters
                                        </li>

                                        <li>
                                        Duplex filters
                                        </li>

                                        <li>
                                        Bag / Multi-bag filter
                                        </li>

                                        <li>
                                        Vacuum dehydrator
                                        </li>
                                        
                                    </ul>

                                    <h4>
                                    Strainers
                                    </h4>

                                    <ul>
                                        <li>
                                        Y type
                                        </li>

                                        <li>
                                        T type
                                        </li>

                                        <li>
                                        Bucket type
                                        </li>

                                        <li>
                                        Duplex type
                                        </li>
                                    </ul>

                                    <h4>
                                        Dished Ends
                                    </h4>
                                    
                                    <ul>
                                        <li>
                                        Shallow-type dished ends
                                        </li>

                                        <li>
                                        Elliptical dished ends (D shape, Double D shape and Ellipse)
                                        </li>
                                    </ul>

                                    <h4>
                                        Sight Glasses and Level Gauges
                                    </h4>
                                </div>

                                <div className="tableCol">
                                    <h4>
                                    Flame Arrestors
                                    </h4>
                                    
                                    <ul>
                                        <li>
                                        In-line Deflagration Arrestor
                                        </li>

                                        <li>
                                        In-line Unstable Detonation Arrestor
                                        </li>

                                        <li>
                                        End-of-line Deflagration Arrestor
                                        </li>
                                    </ul>

                                    <h4>
                                    Tank Safety Equipment
                                    </h4>
                                    
                                    <ul>
                                        <li>
                                        Relief valves
                                        <br/><br/>
                                            *Pressure Safety type
                                            <br/><br/>
                                            *Temperature Safety type
                                        </li>

                                        <li>
                                        Tank Vents and Gauge Hatches
                                        <br/><br/>
                                            *Free vents
                                            <br/><br/>
                                            *Spring-loaded hatches
                                            <br/><br/>
                                            *Deadweight hatches
                                            <br/><br/>
                                            *Lock-down hatches
                                        </li>
                                    </ul>
                                </div>

                                <div className="tableCol">
                                    <h4>
                                        Miscellaneous
                                    </h4>
                                    
                                    <ul>
                                        <li>
                                        Expansion joints/bellows
                                        </li>

                                        <li>
                                        Insulation joints
                                        </li>

                                        <li>
                                        Bird screens
                                        </li>

                                        <li>
                                        Choke pipes
                                        </li>

                                        <li>
                                        Exhaust heads
                                        </li>

                                        <li>
                                        Injection nozzles
                                        </li>

                                        <li>
                                        Sample coolers
                                        </li>

                                        <li>
                                        Seal pots
                                        </li>

                                        <li>
                                        Thermal sleeves
                                        </li>

                                        <li>
                                        Special forgings
                                        </li>

                                        <li>
                                        Pipe supports
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};

export default Specialty;