import "./sendMessage.css";

const SendMessage = () => {
    return(
        <>
            <div className="sendMessageCont">
                <h1>
                    Send Us A Message
                </h1>
                <form className="sendMessageForm" action="https://formsubmit.co/nfo@graceprocurementservices.com">
                    <input type="text" id="name" placeholder="Your name" name="text" required/>
                    <input type="email" id="email" placeholder="Your email" name="email" required/>

                    <input type="number" id="number" placeholder="Phone number" name="number" required/>
                    <input type="text" id="text" placeholder="Company" name="text" required/>

                    <textarea placeholder="Drop a message"/>

                    <button type="submit">
                        Send
                    </button>
                </form>
            </div>
            
        </>
    );
};

export default SendMessage;