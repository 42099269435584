/* eslint-disable jsx-a11y/alt-text */
import "./services.css";
import polygon from "../../assets/Polygon 15.png"

const Solutions = () => {
    return(
        <>
            <div className="solutionsBody">
                <h1>
                    Our Solutions
                </h1>

                <div className="solutionPolyBody">
                    <div className="solutionPolygon">
                        <img src={polygon}/>
                        <div className="polygonTextTop">
                            <p>
                                TECHNICAL
                                <br/>
                                PARTNERSHIP
                            </p>
                        </div>
                    </div>


                    <div className="solutionsPolygonBottom">
                        <img src={polygon}/>
                        <div className="polygonText">
                            <p>
                                procurement
                                <br/>
                                execution
                                <br/>
                                management
                            </p>
                        </div>
                    </div>


                    <div className="solutionPolygon">
                        <img src={polygon}/>
                        <div className="polygonTextTop">
                            <p>
                                SOURCING/
                                <br/>
                                RESOURCING
                            </p>
                        </div>
                    </div>


                    <div className="solutionsPolygonBottom">
                        <img src={polygon}/>
                        <div className="polygonText">
                            <p>
                                logistics
                                <br/>
                                management
                            </p>
                        </div>
                    </div>


                    <div className="solutionPolygon">
                        <img src={polygon}/>
                        <div className="polygonTextTop">
                            <p>
                                project 
                                <br/>
                                management
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default Solutions;