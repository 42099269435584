import React, { useState } from "react";
import { Nav, NavLink, NavMenu, MobileMenuButton, Overlay } from "./NavbarElements";
import "./header.css";
import image from "../../assets/image 1.png"

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };
	return (
		<>
			<Nav class="header">
				<NavMenu>
                    <NavLink to="/" activeStyle>
                        <img src={image}
                        alt='' width={'50%'}
                        />
					</NavLink>

                    <div className="header-centered">
                        <NavLink to="/details" activeStyle>
                            Home
                        </NavLink>

                        <NavLink to="/products" activeStyle>
                            Products
                        </NavLink>

                        <NavLink to="/services" activeStyle>
                            Services
                        </NavLink>

                        <NavLink to="/about" activeStyle>
                            About Us
                        </NavLink>

                        <NavLink to="/projects" activeStyle>
                            Projects
                        </NavLink>

                        <NavLink to="/contact" activeStyle>
                            Contact
                        </NavLink>
                    </div>

                    <div className="header-right">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0,0,256,256">
                            <g fill="#0c91bb" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path></g></g>
                        </svg>
                    </div>
				</NavMenu>

                <MobileMenuButton onClick={toggleMobileMenu}>
                    <img src='https://s3-alpha-sig.figma.com/img/3a92/b6b9/4916fd1474abbeb18e6fe8bd8b8d820b?Expires=1694995200&Signature=XzdEmyekGY5CiXobNz91S1kITGxV7W6txtsXw4cm187LNUe9tBXSy8sko-THJpWwSdHFvJrxzw3U6DrlXRvQxDAK5D6PTt-7NLYMcPIo1klJDNUN7km84ajhCR2V~w4snhM9QNjlfQjdeuHVs42h2oriZfOy5ig7mKPmYt0WdQACeWqOxTgtLd7MD3VJS9r4O~HS0Viv3g2W-fgBIvocVkpcqSq6EpYBIMJMDdNBQiQY3EkTjdkT11qdPti4N5yEM5YvWdyiYSUo-JD0W7J2xnrFbsu3p8mzabLtk8stVfAeIefIfBn1HuGLxczTyF-ThooJWJzb3OXbph8wddjqMg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4' 
                        alt='' width={'50%'}
                    />
                    <div className="toggleSide">
                        ☰
                    </div>
                    
                </MobileMenuButton>
			</Nav>

            <Overlay className={isMobileMenuOpen ? 'active' : ''}>
                <div onClick={closeMobileMenu} className="close">✕</div>
                <NavMenu className={isMobileMenuOpen ? 'active' : ''}>
                    <NavLink to="/details" activeStyle onClick={closeMobileMenu}>
                        Home
                    </NavLink>

                    <NavLink to="/products" activeStyle onClick={closeMobileMenu}>
                        Products
                    </NavLink>

                    <NavLink to="/services" activeStyle onClick={closeMobileMenu}>
                        Services
                    </NavLink>

                    <NavLink to="/about" activeStyle onClick={closeMobileMenu}>
                        About Us
                    </NavLink>

                    <NavLink to="/projects" activeStyle onClick={closeMobileMenu}>
                        Projects
                    </NavLink>

                    <NavLink to="/contact" activeStyle onClick={closeMobileMenu}>
                        Contact
                    </NavLink>
                </NavMenu>
            </Overlay>
		</>
	);
};

export default Navbar;