import "../App.css"
import React, { useEffect } from 'react';
import Slider from '../components/SlideComponent/Slider';
import CoreValue from "../components/AboutUs/coreValue";
import AboutUs from "../components/AboutUs/aboutUs";
import Mission from "../components/AboutUs/mission";
import JobPost from "../components/AboutUs/jobPost";
import Apply from "../components/AboutUs/apply";
import SendMessage from "../components/DetailsPage/sendMessage";
import Subscribe from "../components/HomePage/Subscribe/subscribe";

const About = () => {
	useEffect(() => {
		if (window.location.hash === '#scroll500') {
		  window.scrollTo(0, 500);
		}
		if (window.location.hash === '#scroll1200') {
			window.scrollTo(0, 1200);
		  }
		  if (window.location.hash === '#scroll1500') {
			window.scrollTo(0, 1500);
		  }
		  if (window.location.hash === '#scroll2000') {
			window.scrollTo(0, 2000);
		  }
	}, []);
	return (
		<>
			<Slider />
			<div className="aboutBody">
				<AboutUs id="section-id"/>
				<Mission />
				<CoreValue />
				<br /><br />
				<JobPost />
				<Apply />
			</div>
		</>
	);
};

export default About;
