import "./products.css"
import image1 from "../../assets/electric1.png"
import image2 from "../../assets/electric2.png"
import image3 from "../../assets/electric3.png"
import image4 from "../../assets/electric4.png"
import image5 from "../../assets/elcectric5.png"
import image6 from "../../assets/electric6.png"

const Electrical = () => {
    return(
        <>
            <div className="productsContainer">
                <div className="topSpacingStruc">
                    <h1>
                        Electrical & Instrumentation 
                    </h1>

                    <div class="productStructuralBG">
                        <div className="productContainerCenter">
                            <div class="row">
                                <div class="productColumn">   
                                    <div class="productTextContainer">
                                        <img src={image1} alt="" class="regularImage" />
                                        <div class="productCenteredText">
                                            <p>
                                            Diaphragm / tubular Seals
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productColumn">
                                    <div class="productTextContainer">
                                        <img src={image2} alt="" class="centeredProductImage" />
                                        <div class="productCenteredImageCont">
                                            <p>
                                            Level
                                            <br/>
                                            Pressure
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="productColumn">
                                    <div class="productTextContainer">
                                        <img src={image3} alt="" class="regularImage" />
                                        <div class="productCenteredText">
                                            <p>
                                                Tube manometers
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                    <div class="productColumn">   
                                        <div class="productTextContainer">
                                            <img src={image4} alt="" class="regularImage" />
                                            <div class="productCenteredText">
                                                <p>
                                                Sensors/transducers
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="productColumn">
                                        <div class="productTextContainer">
                                            <img src={image5} alt="" class="centeredProductImageBottom" />
                                            <div class="centeredImageContBottom">
                                                <p>
                                                Flow
                                                <br/>
                                                Temperature
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="productColumn">
                                        <div class="productTextContainer">
                                            <img src={image6} alt="" class="regularImage" />
                                            <div class="productCenteredText">
                                                <p>
                                                Test & Calibration
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="table">
                        <div className="tableColCont">
                            <div className="row">
                                <div className="tableCol">

                                    <h4>
                                        Temperature
                                    </h4>

                                    <ul>
                                        <li>
                                        RTD Sensing elements (probes, sensors…)
                                        </li>

                                        <li>
                                        Infrared pyrometers
                                        </li>

                                        <li>
                                        Bimetallic thermometers
                                        </li>

                                        <li>
                                        Gas filled thermometers
                                        </li>

                                        <li>
                                        Mechanical switches
                                        </li>

                                        <li>
                                        Accessories
                                        </li>
                                    </ul>

                                    <h4>
                                    Test & Calibration
                                    </h4>

                                    <ul>
                                        <li>
                                        Precision pressure gauges
                                        </li>

                                        <li>
                                        Tube manometers
                                        </li>

                                        <li>
                                        Pressure generators
                                        </li>

                                        <li>
                                        Portable pressure calibrators
                                        </li>

                                        <li>
                                        Portable calibrating systems
                                        </li>
                                    </ul>
                                </div>

                                <div className="tableCol">
                                    <h4>
                                    Pressure
                                    </h4>
                                    
                                    <ul>
                                        <li>
                                            Switches (gauge or differential types)
                                        </li>

                                        <li>
                                            Gauges (tube, capsule, diaphragm, differential types)
                                        </li>

                                        <li>
                                            Sensors/transducers
                                        </li>

                                        <li>
                                            Transmitters (fixed range or smart and configurable type)
                                        </li>

                                        <li>
                                        Diaphragm / tubular Seals 
                                        </li>

                                        <li>
                                            Pressure limiters, Dampeners, siphons, cocks & valves
                                        </li>
                                    </ul>

                                    <h4>
                                        Test & Calibration
                                    </h4>
                                    
                                    <ul>
                                        <li>
                                            Dead-weight tester
                                        </li>

                                        <li>
                                            Dry block portable calibrators
                                        </li>

                                        <li>
                                            Portable/Non-portable temperature baths
                                        </li>

                                        <li>
                                            Reference temperature probes
                                        </li>
                                    </ul>
                                </div>

                                <div className="tableCol">

                                    <h4>
                                        Level
                                    </h4>
                                    
                                    <ul>
                                        <li>
                                            Potentiometric level measurement
                                        </li>

                                        <li>
                                            Level switches/sensors for hygienic application
                                        </li>

                                        <li>
                                            Magnetic or glass level gauges
                                        </li>

                                        <li>
                                            Magnetostrictive level transmitters
                                        </li>

                                        <li>
                                            Guided wave radar transmitters
                                        </li>

                                        <li>
                                            Laser transmitters
                                        </li>

                                        <li>
                                            Ultrasonic transmitters & switches
                                        </li>

                                        <li>
                                            Vibrating level switches
                                        </li>
                                    </ul>

                                    <h4>
                                        Flow
                                    </h4>
                                    
                                    <ul>
                                        <li>
                                        Transmitters
                                        </li>

                                        <li>
                                        Switches
                                        </li>

                                        <li>
                                        Meters
                                        </li>
                                    </ul>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="pagination">
                    <a className="notactive" href="/products">Previous</a>
                    <a className="active" href="/products">| &nbsp; 1  &nbsp; |</a>
                    <a className="notactive" href="/productsTwo">2</a>
                    <a className="active" href="/productsTwo">| &nbsp; Next</a>
                </div>
            </div>
        </>
    );
};

export default Electrical;