import React from 'react';
import OurServices from '../components/Services/ourServices';
import Solutions from '../components/Services/solutions';
import SendMessage from '../components/DetailsPage/sendMessage';
import Subscribe from '../components/HomePage/Subscribe/subscribe';
import Slider from '../components/SlideComponent/Slider';

const Services = () => {
	return (
		<div>
			<Slider />
			<OurServices />
			<Solutions />
		</div>
	);
};

export default Services;
