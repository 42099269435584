import './subscribe.css';

function Subscribe() {
  return (
      <>
        <div className='subscribeContainer'>
            <h1>
                Subscribe to our newsletter for updates and latest news 
            </h1>

            <form class="subscribeForm" action="https://formsubmit.co/nfo@graceprocurementservices.com">
                <input type="email" id="email" placeholder="Enter email" name="email" />
                <button type="submit">
                    Subscribe
                </button>
            </form>

        </div>
      </>
  )
}

export default Subscribe;