/* eslint-disable jsx-a11y/alt-text */
import './service.css';
import Icons from './icons';
import image from "../../../assets/Rectangle13.png"

function Services() {
  return (
    <div className='servicesCont'>
        <h1>
            Explore
        </h1>
        
        <div className="background-div">
            <div className='container'>
                <div className='row'>
                        <div className='services text'>
                            <div className='textCont'>
                                <h2>
                                    Our Services
                                </h2>

                                <p>
                                    Grace Procurement Service Limited, a supply chain management company committed to providing cost competitive technical procurement and logistics solution in the oil and gas industry worldwide. Our goal is to meet the expectation of every client by offering outstanding customer service, increased flexibility and great value, thus optimizing system functionality and improving operation efficiency.  
                                </p>
                            </div>
                        </div>

                        <div className='services image'>
                            <img src={image} />
                        </div>
                </div>

                <div className='containerCenter'>
                    <h2>
                        Services
                    </h2>

                    <Icons />
                </div>
            </div>
        </div>
    </div>
  );
}

export default Services;