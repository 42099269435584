import React, { useEffect, useState } from 'react';
import './headSlider.css';

const CounterNumber = () => {
    const [counter, setCounter] = useState(13);
    const animationDuration = 3000;
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (counter < 40) {
          setCounter((prevCounter) => prevCounter + 1);
        }
      }, 40);
  
      setTimeout(() => {
        clearInterval(interval);
        document.querySelector('.counter-text').classList.remove('bounce-animation');
      }, animationDuration);
  
      return () => {
        clearInterval(interval);
      };
    }, [counter]);
  
    useEffect(() => {
      if (counter === 40) {
        document.querySelector('.counter-text').classList.remove('bounce-animation');
      } else {
        document.querySelector('.counter-text').classList.add('bounce-animation');
      }
    }, [counter]);
  
    return (
      <div className="floating-counter">
        <div className={`counter-text bounce-animation`}>{counter}+</div>
      </div>
    );
};

export default CounterNumber;