/* eslint-disable jsx-a11y/alt-text */
import "./about.css";
import headImage from "../../assets/Group 101.png"

const AboutUs = () =>{
    return(
        <>
            <div className="row">
                <div className="aboutCont">
                    <div className="aboutColumn">
                        <h1 className="floating-text" id="aboutus">
                            Who We Are
                        </h1>
                        
                        <p className="floating-text">
                            We are a technical procurement and logistic expert from Nigeria, serving oil and gas industry. We helps our clients to optimize the technical procurements and logistics processes. We take clients operational procurement to a higher level by offering a complete range of services, from sourcing to execution and logistics at competitive price.
                            Our exceptional service delivery within the industry is engendered by our highly experienced, motivated and dedicated team coupled with our robust and rich international network and thriving relationship with  leading and approved Original Equipment Manufacturers (OEMs) and stockist in Asia, Americas,  Europe and the middle East.
                        </p>
                    </div>

                    <div className="aboutColumn">
                        <img src={headImage} />
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default AboutUs;