import React, { useState, useEffect } from 'react';
import './headSlider.css';
import SlideContent from './slideContent';
import image1 from "../../../assets/Rectangle2.png";
import image2 from "../../../assets/Rectanglegps1.png";
import image3 from "../../../assets/Rectanglegps2.png";

const Slider = () => {
  const shuffledImages = [image1, image2, image3];

  for (let i = shuffledImages.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledImages[i], shuffledImages[j]] = [shuffledImages[j], shuffledImages[i]];
  }

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % shuffledImages.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [shuffledImages.length]);

  return (
    <div className="slideshowHome-container">
      {shuffledImages.map((image, index) => (
        <div
          key={index}
          className={`slideHome ${index === currentSlide ? 'active' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <SlideContent />
        </div>
      ))}
      <div className="dotHome-container">
        {shuffledImages.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;
