/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import "./contact.css";
import image1 from "../../assets/conatctgps1.png"
import image2 from "../../assets/contactgps2.png"
import image3 from "../../assets/contactgps3.png"

const Contacts = () =>{
    return(
        <>
            <div>
                <div className="row">
                    <div className="contactCont">
                        <div className="contactCol">
                            <div className="contactCard">
                                <img src={image1} />
                                
                                <h1>
                                    Email Us
                                </h1>

                                <a href="mailto:info@graceprocurementservices.com" className="link">
                                    info@graceprocurementservices.com
                                </a>

                                <br />

                                <a href="mailto:info.graceprocurement@gmail.com" className="link">
                                    info.graceprocurement@gmail.com
                                </a>

                            </div>
                        </div>

                        <div className="contactCol">
                            <div className="contactCard">
                                <div className="borderedImg">
                                    <img src={image2} />
                                </div>
                                
                                <h1>
                                    Our Address
                                </h1>

                                <p>
                                    39 Alfred Rewane Road Ikoyi, Lagos, State
                                </p>
                            </div>
                        </div>

                        <div className="contactCol">
                            <div className="contactCard">
                                <img src={image3} />
                                <h1>
                                    Call Us
                                </h1>

                                <div className="number">
                                    +2349133466685
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mapContainer">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15858.559828486115!2d3.416071069094846!3d6.440261061495906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2f8eb475af%3A0x37ae968bdb14a551!2s39%20Alfred%20Rewane%20Rd%2C%20Ikoyi%20106104%2C%20Lagos!5e0!3m2!1sen!2sng!4v1694391292818!5m2!1sen!2sng" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                
            </div>
        </>
    );
};

export default Contacts;