/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import './App.css';
import Navbar from './components/Navbar/navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import HomePage from './pages/home';
import About from './pages/about';
import Products from './pages/products';
import ProductsTwo from './pages/productsTwo';
import Projects from './pages/projects';
import Services from './pages/services';
import Contact from './pages/contact';
import Footer from './components/Footer/footer';
import AboutUs from './components/AboutUs/aboutUs';

function App() {
	return (
		<Router>
			<Navbar />
			<Routes>
				<Route path='/' exact element={<Home />} />
				<Route path='/about' element={<About />} />
				<Route path='/contact' element={<Contact />} />
				<Route path='/details' element={<HomePage />} />
				<Route path='/products' element={<Products />} />
				<Route path='/productsTwo' element={<ProductsTwo />} />
				<Route path='/projects' element={<Projects />} />
				<Route path='/services' element={<Services />} />


				<Route path="/aboutus" element={<AboutUs />} />
			</Routes>
			<Footer />
		</Router>
	);
}

export default App;
